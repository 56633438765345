<template>
  <div id="app">
      <!--<Inicio></Inicio>-->
      <router-view></router-view>
  </div>
</template>

<script>

  import '../node_modules/bootstrap/dist/js/bootstrap.js';
  import '../node_modules/jquery/dist/jquery.js';

// import './assets/js/jquery.min.js';
// import './assets/js/jquery.easing.min.js';
// import './assets/js/swiper.min.js';
// import './assets/js/jquery.magnific-popup.js';
// import './assets/js/validator.min.js';
// import './assets/js/scripts';
// import './assets/js/scripts.js';
  

  window.$=require('jquery')
  window.JQuery=require('jquery')
/*
import './assets/js/popper.min.js';
import './assets/js/bootstrap.min.js';
*/

// import './assets/js/main.js';


//import Inicio from './components/Inicio.vue'

export default {
  name: 'App',
  components: {
    //Inicio
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap');
@import '../node_modules/bootstrap/dist/css/bootstrap.css';

@import './assets/css/styles.css';
@import './assets/css/estilo.css';



#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
