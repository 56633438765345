<template>
  <div>
    <ModalTerminos></ModalTerminos>
    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
      <!-- Text Logo - Use this if you don't have a graphic logo -->
      <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Evolo</a> -->

      <!-- Image Logo -->
      <a class="navbar-brand logo-image" href="/">
        <!-- <img
          src="https://keepon-test.s3.us-east-2.amazonaws.com/images/webkeepon/logokeepon2.jpg"
          class="img-fluid"
          alt="alternative"/> -->
          <strong>
          <span class="brandText2"> UNIFIT</span>   
          </strong>

      </a>

      <!-- Mobile Menu Toggle Button -->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="botonmenumobile bi bi-menu-button"
          viewBox="0 0 16 16"
        >
          <path
            d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-8z"
          />
          <path
            d="m7.823 2.823-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
      <!-- end of mobile menu toggle button -->

      <div class="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link page-scroll" href="/"
              >Inicio <span class="sr-only">(current)</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#services">Caracteristicas</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link page-scroll" href="#pricing">Precios</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#video">Video</a>
          </li>

          <!-- Dropdown Menu -->
          <li class="nav-item">
            <span v-on:click="verTerminos()" class="cursorpointer nav-link page-scroll">Privacidad</span>
            
          </li>
          <!-- end of dropdown menu -->

          <li class="nav-item">
            <a class="nav-link page-scroll" href="#contact">Registro</a>
          </li>
        </ul>
        <span class="nav-item social-icons">
          <span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x facebook"></i>
              <i class="fab fa-facebook-f fa-stack-1x"></i>
            </a>
          </span>
          <span class="fa-stack">
            <a href="#your-link">
              <i class="fas fa-circle fa-stack-2x twitter"></i>
              <i class="fab fa-twitter fa-stack-1x"></i>
            </a>
          </span>
        </span>
      </div>
    </nav>
    <!-- end of navbar -->
    <!-- end of navigation -->
  </div>
</template>
<script>
import ModalTerminos from '@/components/ModalTerminos';
var $=require('jquery');

export default {

  name: "Toolbar",
  components:{ModalTerminos},
  methods: {

    verTerminos(){
      $('.modalTerminos').modal('show');
    }
    
  },

};
</script>
