<template>
    <div>
    <ModalVideo></ModalVideo>
         <!-- Video -->
    <div  id="video" class="basic-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Video</h2>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="col-lg-12">
                    
                    <!-- Video Preview -->
                    <div class="image-container">
                        <div class="video-wrapper">
                            <div v-on:click="verVideo()" class="tv">
                                <img class="img-fluid" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/video-frame.svg" alt="alternative">
                                <span class="video-play-button">
                                    <span></span>
                                </span>
                            </div>
                        </div> <!-- end of video-wrapper -->
                    </div> <!-- end of image-container -->
                    <!-- end of video preview -->
                    <p>Toma unos minutos para observar un video con una descripción detallada de lo que <strong>Keepon</strong> puede hacer por tí</p>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-3 -->
    <!-- end of video -->
    </div>
</template>
<script>
import ModalVideo from '@/components/ModalVideo';
var $=require('jquery');
export default {
    name:'Video',
    components:{ModalVideo},
    methods: {
        verVideo(){
             $('.modalVideo').modal('show');
             console.log("Llego a modal video");
        }
    },
    
}
</script>
<style lang="">
    
</style>