<template>
       <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="p-small">Copyright © 2024 <a href="https://www.unifit.mx">UNIFIT</a> - All rights reserved</p>
                </div> <!-- end of col -->
            </div> <!-- enf of row -->
        </div> <!-- end of container -->
    </div> <!-- end of copyright --> 
</template>
<script>
export default {
    name:'Copyright'
}
</script>
<style lang="">
    
</style>