<template>
      <!-- Contact -->
    <div id="contact" class="form-2">
        <ModalTerminos></ModalTerminos>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Registrate gratis y obten todos los beneficios</h2>
                    <ul class="list-unstyled li-space-lg">
                        <li class="address">Registrate y obten dos meses de prueba gratis, y disfruta de todos los beneficios que <span class="brandText2">UNI</span><span class="brandText2">FIT</span> tiene para ti</li>
                        <!-- <li><i class="fas fa-map-marker-alt"></i>Camino real N° 2, San Jerónimo Yahuiche, Atzompa, Oaxaca, México</li> -->
                        <!-- <li><i class="fas fa-phone"></i><a class="turquoise" href="tel:+529514120650">+52 951 412 06 50</a></li>
                        <li><i class="fas fa-envelope"></i><a class="turquoise" href="mailto:lambdax.info@gmail.com">lambdax.info@gmail.com</a></li> -->
                    </ul>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="d-none d-lg-block col-lg-6">
                    <div class="map-responsive">
                        <img src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/undraw_Activity_tracker_re_2lvv.png" class="img-fluid" alt="">
                    </div>
                </div> <!-- end of col -->
                <div class="col-12 col-lg-6 registro">
                   
                    
                    <!-- Contact Form -->
                    <!--<form id="contactForm" @submit.prevent="valida()">-->
                    <div>
                        <div class="form-group">
                            <label class="" for="cname">Nombre del gimnasio</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="nombreInstitucion" 
                                id="cname" 
                                placeholder="Ingresa el nombre de tu centro deportivo"
                                autocomplete="off"
                                >
                        </div>
                        <div class="form-group">
                            <label class="" for="cemail">Correo electrónico</label>
                            <input type="email" class="form-control" v-model="correo" id="cemail">
                            <div v-if="correctFormat" class="infoCardblack">
                                Respeta el formato micuenta@correo.com
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="" for="password">Contraseña</label>
                            <input type="password" class="form-control" v-model="password" id="password">
                            <div v-if="minLenghtPass1" class="infoCardblack">
                                Al menos 6 caracteres
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="" for="cpassword">Confirma contraseña</label>
                            <input type="password" class="form-control" v-model="cpassword" id="cpassword">
                            <div v-if="minLenghtPass2" class="infoCardblack">
                                Al menos 6 caracteres
                            </div>
                            <div v-if="passEquals" class="infoCardblack">
                                Las contraseñas deben coincidir
                            </div>
                        </div>

                        <!--<div class="form-group">
                            <vue-recaptcha sitekey="6Ld4bEAbAAAAAOAKtVDq6UKsYKVBp0I8T1nvODu7" :loadRecaptchaScript="true" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
                        </div>-->
                        <div class="form-group checkbox">
                            Al registrarte aceptas los <span v-on:click="modalTerminos()" class="terminosWords">terminos y condiciones</span> del servicio
                        </div>
                        <div class="form-group">
                            <div id="fb-root"></div>
                            <button type="submit" v-if="!isDisabled" class="form-control-submit-button" v-on:click="valida()">REGISTRARME</button>
                            <button type="submit" v-else id="btnDisabled" disabled class="form-control-submit-button">REGISTRARME</button>
                        </div>

                        <div class="">
                            <a target="_blank" href="https://admin.unifit.mx/login">Ya tengo una cuenta</a>
                        </div>
                        <!--<div class="form-group">
                            <div class="formm">
                                <button v-if="correctNameInstitution" class="form-control-submit-button-facebook-disabled">
                                        Registrarse con Facebook
                                </button>
                                <button v-if="!correctNameInstitution" class="form-control-submit-button-facebook" @click="login">
                                    <i class="fa fa-facebook mr-1"></i>
                                        Registrarse con Facebook
                                </button>
                                </div>
                            <div id="fb-root"></div>-->
                            <!--<button type="submit" v-if="!isDisabled & robot" class="form-control-submit-button">REGISTRARME</button>
                            <button type="submit" v-else id="btnDisabled" disabled class="form-control-submit-button">REGISTRARME</button>-->
                            
                        <!--</div>-->
                        <!--<div class="form-group">
                            <div class="formm">
                                <button class="form-control-submit-button-google" @click="login">
                                    <i class="fa fa-facebook mr-1"></i>
                                        Registrarse con Google
                                    </button>
                                </div>
                            <div id="fb-rootttt"></div>-->
                            <!--<button type="submit" v-if="!isDisabled & robot" class="form-control-submit-button">REGISTRARME</button>
                            <button type="submit" v-else id="btnDisabled" disabled class="form-control-submit-button">REGISTRARME</button>-->
                            
                        <!--</div>-->
                        <!--<div class="form-message">
                            <div id="cmsgSubmit" class="h3 text-center hidden"></div>
                        </div>-->
                    <!--</form>-->
                    </div>
                    <!-- end of contact form -->

                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of form-2 -->
    <!-- end of contact -->

</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2'
import ModalTerminos from '@/components/ModalTerminos';
import { authenticationService } from '@/_services';
//import VueRecaptcha from 'vue-recaptcha';
var $=require('jquery');

export default {
    name:'Contacto',
    data(){
        return {
            
            nombreInstitucion:null,
            correo:null,
            password:null,
            cpassword:null,
            // terminos:false,
            isDisabled:true,
            correctFormat:false,
			minLenghtPass1:false,
			minLenghtPass2:false,
			passEquals:false,
            correctNameInstitution:true,
            robot: false,
            tokenCaptcha:''

        }
    },
    components:{ModalTerminos, /*VueRecaptcha*/},
    methods:{
        login(){
            authenticationService.login(this.nombreInstitucion)
        },
        submit: function() {
            if (this.robot) {
                console.log("ok");
            }
        },
        onExpired: function () {
            this.robot = false;
        },
        onVerify: function (response) {
            this.tokenCaptcha=response;
            console.log(this.tokenCaptcha);
            console.log(response);
            if (response) this.robot = true;
        },
        modalTerminos(){

            $('.modalTerminos').modal('show');

        },

        validacionAparte(){

				var formatoEmail = /^([\da-z_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;

                if(this.nombreInstitucion==null || this.nombreInstitucion==''){
                    this.isDisabled=true;
					this.correctNameInstitution=true;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=false;

                }else if (this.correo==null || this.correo=='') { 	
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=false;
					
				}else if (!formatoEmail.test(this.correo)){
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=true;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=false;
				}else if (this.password==null || this.password==''){
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=false;
					
				}else if (this.password.length<6){
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=true;
					this.minLenghtPass2=false;
					this.passEquals=false;
					
				}else if (this.cpassword==null || this.cpassword==''){
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=false;
					
				}else if (this.cpassword.length<6){
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=false;
					this.minLenghtPass2=true;
					this.passEquals=false;
					
				}else if (this.password!=this.cpassword){
					this.isDisabled=true;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=true;
					
				}else{
					this.isDisabled=false;
                    this.correctNameInstitution=false;
					this.correctFormat=false;
					this.minLenghtPass1=false;
					this.minLenghtPass2=false;
					this.passEquals=false;


				}


			},


        valida(){

            

            console.log("llego");
            console.log(this.nombreInstitucion);
            console.log(this.correo);
            console.log(this.password);
            console.log(this.cpassword);

            // Swal.fire({
            //           position: 'center',
            //           icon: 'success',
            //           title: 'Registrado exitosamente',
            //           showConfirmButton: true,
            //           timer: 1500
            //         })


/********PETICION AXIOS*************************************** */

        axios.post(this.$hostname+'/api_register/register/gym', {          
            nombre: this.nombreInstitucion,
            email: this.correo,
            password: this.password,
            password2:this.password,
            terminos: true
                  },{
                    auth: { username:'frontendapp', password:'12345' },
                      })

        .then((res)=> {
            console.log("que me regresa el servicio:::") 
          console.log(res.data)

            Swal.fire(
             'Registrado exitosamente!',
             'Te enviamos un correo electrónico a <b>' +this.correo+ '</b>, para que confirmes tu cuenta y disfrutes de <span class="brandText1">UNI</span><span class="brandText2">FIT</span>',
             'success'
             )         

            console.log("procedemos a resetear formulario");
            this.tokenCaptcha='';
            this.robot=false;
            this.reset();

          
      }).catch(function(err)
        {
            console.log("que trae el error:::");
            console.log(err)

            Swal.fire(
             'Error verfica datos de registro!',
             'Hubo un error<b>' +err+ '</b>, en el proceso de registro',
             'error'
             )
             
             this.tokenCaptcha='';
            this.robot=false;
            this.reset();


          if(err.response.status==403){
              console.log("error 403");
              Swal.fire(
             'Error verfica datos de registro!',
             'Hubo un error<b>' +err+ '</b>, en el proceso de registro',
             'error'
             ) 
              this.reset();
          }else{
            console.log("otra cosa");
            Swal.fire(
             'Error verfica datos de registro!',
             'Hubo un error<b>' +err+ '</b>, en el proceso de registro',
             'error'
             ) 
            this.reset();
          }
        
        });

/************************************************************************************** */


            
        },

        reset(){

        Object.assign(this.$data, this.$options.data.call(this));

        },
    },

    watch:{
            nombreInstitucion: function(){
				this.validacionAparte();

			},

			correo: function(){
				this.validacionAparte();

			},

			password: function(){
				this.validacionAparte();

			},
			cpassword: function(){
				this.validacionAparte();

			}
		},
    
}
</script>
<style lang="">
    
</style>