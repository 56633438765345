<template>

<div class="">

<div class="modal fade modal3" id="modal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body">
        <!-- <div id="details-lightbox-1" class=""> -->
        <div class="container seccionprincipalmodal">
            <div v-if="flagActiveModal" class="row">
               
                <div class="col-lg-7 imagenm1">
                    <div class="image-container">
                        <img class="img-fluid" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/undraw_visual_data_b1wx.svg" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
                
                <div class="col-lg-4 textom1">
                    <strong><h3>UNIFIT es único en el control de la venta de productos</h3></strong>
                    <div class="linea">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="lineTitle bi bi-dash-lg" viewBox="0 0 16 16">
                      <path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="lineTitle bi bi-dash-lg" viewBox="0 0 16 16">
                      <path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/>
                    </svg>
                    
                    </div>
                    <br>
                    
                    <p class="parrafomodal">Sabemos que existen herramientas para llevar el control de tus ventas, pero UNIFIT concentra en un solo lugar las herramientas mas poderosas para que logres llevar a cabo un perfecto control de la venta de tus productos, de una forma simple e intuitiva.</p>
                    <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>

                            <div class="masespacio media-body">Vende productos de forma fácil y rápida</div>
                        </li>
                        <li class="media">
                           
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                    
                            <div class="masespacio media-body">Agrega diferentes tipos de productos, no importa si son a granel o por lote</div>
                        </li>
                        <li class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>

                            <div class="masespacio media-body">Elimina productos que ya no tengas en venta</div>
                        </li>
                        <li class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                            <div class="masespacio media-body">Modifica precios o nombres de productos</div>
                        </li>
                        <li class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                            <div class="masespacio media-body">Controla el stock de tus productos, es decir cuantos vendiste, y cuantos tienes</div>
                        </li>
                        <li class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                            <div class="masespacio media-body">Realiza tus cortes de caja de manera rápida e intiuitiva</div>
                        </li>

                        <li class="media">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                          <div class="masespacio media-body">UNIFIT te notifica si tus productos estan por caducar</div>
                        </li>

                        <li class="media">
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                           <div class="masespacio media-body">Elige si deseas imprimir ticket o no (Por defecto los tickets están diseñados para papel de 80mm)</div>
                        </li>
                        <li class="media">
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg palomitaCheck" viewBox="0 0 16 16">
                            <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                           <div class="masespacio media-body">Descarga tus cortes de caja en pdf</div>
                        </li>
                        
                    </ul>
                    <br>
                  <button type="button" class="btn-outline-reg mfp-close as-button" data-dismiss="modal">CERRAR</button>

                  &nbsp;
                  
                  <button @click="registrarme()" type="button" class="btn-outline-reg mfp-close as-button" data-dismiss="modal">REGISTRARME</button>
                </div> <!-- end of col -->
            </div> <!-- end of row -->

            <div v-else class="">

            <Contacto></Contacto>
            <br>
            <button @click="regresar()" type="button" class="btn-outline-reg mfp-close as-button">REGRESAR</button>    
            </div>

        </div> <!-- end of container -->
    <!-- </div> end of lightbox-basic -->
      </div>
      
    </div>
  </div>
</div>



</div>
        
</template>
<script>
import Contacto from "./Contacto.vue";
export default {
    name:'Modal3',
     components:{
      Contacto
    },
    data (){
      return {
        flagActiveModal:true
      }
    },
    methods: {

      registrarme(){
        this.flagActiveModal=false
      },


      regresar(){

        this.flagActiveModal=true
      }
    }
    
}
</script>
<style lang="">
    
</style>