import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from 'axios';
//import JSEncrypt from 'jsencrypt/bin/jsencrypt';

import {initFacebookSdk} from './_helpers';

// import { Swiper, SwiperSlide } from 'swiper/vue';

// Vue.use(Swiper, SwiperSlide);

Vue.config.productionTip = false

api.defaults.timeout = 10000;
 /*api.interceptors.request.use(
   config => {
     let encryptor = new JSEncrypt() // New JSEncrypt Object 
     let publicKey = `MIIEIjANBgkqhkiG9w0BAQEFAAOCBA8AMIIECgKCBAEAynBywWov5USKW4Vg+P6jQ1nLilkoLx1jZM4YWwl545fP3Y8phBBwWjZVyvGWkndnRQzU9+MU7AV+YmA3nA1iPagE5td9GgMZL9Qjp06hbvT8kTVBD+VNdId3uES83FAgB8I0bxyebKqcxdM/vlm2ht2oiksu9wIHC8gqB5RWpn3bl4MG9EYFFJeSFNAZapp9lTQTMVZfmKm9G0moGHi+8dVXLdYn0vtOrdnzZy6kG94pogxSQIx8ehKhFPtJKZz84fmDuUop/vuZ04FyjZA8F0a0qoaGyftHpy6PImEF7gRSqAZtZIB2lQ9yNQTXN7JulWds0dMjJrxyqe2YVzVQRjW2QmTg1UbyG7XLRqqHnrLVpShAoHwQmTDnxEgaA+KT9PMl3N0hkOJ8mm0hyAfAznZfPvYzZEbxff00YczAmULSvHvs8JpnR3TaqgPjKHPWHf2MmcCDl9YYq554DwoR39PhY+hyN+p3gZeN53vez+gqxldLVCWZDDK/zYf4TMNnJFVOhAUK5WRwQQDZuifXPaZEVzwn61/d3Lt0yRyvLoK9vvh/0QWiQP/3k7LUX5ueWVCiDIHXciCWcJYo7ljLYHcrURVx8GnoSLspAobnEv6zBl6ZDz/mRGFYXCVjY/nmA+SoNIEpYOP16qOQT0q/+V+o+UbkThmqRFrNI0bI5jNcApZq/y0cBrasU6l34F8+oLcl7Y8oaXOXyGRg/O0CLpXVY2/f/UpO+XnbMsh8NnBAUJJcxko1ANUFBMqIi6yMGwVAJDw1AX7GTogCIv/jay1BTI9P7QhwCY6gNQsm0x1HzNyYJtYZaIggU19R0TxTkfUhLiUJXiQ6kXnh4oIYoo4uukDY1qNfIUsE0Xn+GZCW+4LAStG5dxEMnq7WNq0n+w9M9EU34EITc3iFUqP+vMZ3fhHMqrDx8JrR6VK30EU8wqNvs6amU7xAC07npk7A4aHy4Z54Di9RTs+EWKQJOSthhLzsPo0ZzFGpaUJP6Bn95cvAHNpypk8VXAvRwLy3xaGHBQzwDM1YcUauq8HAYAL1Kj27uMPbu/XDOfzWHvq7QLfpC2AnX7wv/LyBQ62r/fJDmMtspSkAkLGmc08VsKSOvzlnhSbc8LOZGMJRTAFxKE+Si97CbzS71SFPm631JFm7u81ykRiZcGTnKw/swTxSS0X8Afalw+PpbcyLd6qgf0F2Du1TEsu1WTNs2zePZtQvGul5BXPwleA+dNL90JJ2l1nWKsAfQakwHAL3W6BCmNz5vs9o3IeyePr+iGiCZWYBmz6qQmajpt7yvBjZhbeJZUnNhdfGrqqqs9KyATCjLlZGT5EXc48cbxTwsTlAdvMZmoB8eNkvQc4SPwm7KQIDAQAB`  //Paste in previous generations, which can be passed in from the background during actual development 
     encryptor.setPublicKey(publicKey) // Set Public Key
     const myJSON = JSON.stringify(config.data);
     let rsaPassWord = encryptor.encrypt(myJSON) // Encrypt data that needs to be encrypted
    config.data={data:rsaPassWord}
     return config;
   },
   error => {
     return Promise.reject(error);
   }
 );*/

// api.interceptors.response.use(
//   response => {
//     if (response.status === 200 || response.status === 201) {
//       return Promise.resolve(response);
//     } else {
//       return Promise.reject(response);
//     }
//   },
// error => {
//     if (error.response.status) {

//       switch (error.response.status) {
//         case 400:
//           break;
//         case 401:
//           router.replace({
//             path: "/login",
//             query: { redirect: router.currentRoute.fullPath }
//           });
//           break;
//         case 403:
//           router.replace({
//             path: "/login",
//             query: { redirect: router.currentRoute.fullPath }
//           });
//            break;
//         case 404:
//           alert('page not exist');
//           break;
//         case 502:
//          setTimeout(() => {
//             router.replace({
//               path: "/login",
//               query: {
//                 redirect: router.currentRoute.fullPath
//               }
//             });
//           }, 1000);
//       }
//       return Promise.reject(error.response);
//     }
//   }
// );

// Vue.prototype.$hostname = 'http://10.0.0.33:8762'
// Vue.prototype.$hostname = 'http://localhost:8762'
Vue.prototype.$hostname = 'https://api.unifit.mx'

/*new Vue({
  render: h => h(App),
}).$mount('#app')*/
initFacebookSdk().then(startApp);

function startApp() {
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
}