import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

import { router } from '@/_helpers';

//const baseUrlLogin = `${process.env.VUE_APP_API_URL}/security-social/login/oauth/authorizations`;
const baseUrlLogin = `http://localhost:8762/security-social/register/oauth/authorizations`;
const authenticationSubject = new BehaviorSubject(null);

export const authenticationService = {
    login,
    apiAuthenticate,
    logout,
    getAll,
    getById,
    update,
    delete: _delete,
    authentication: authenticationSubject.asObservable(),
    get authenticationValue () { return authenticationSubject.value; }
};

async function login(nombreGym) {
    // login with facebook then authenticate with the API to get a JWT auth token
    console.log("logueando");
    const { authResponse } = await new Promise(FB.login);
    console.log("logueando2");
    if (!authResponse) return;
    console.log("logueando3");
    await apiAuthenticate(nombreGym, authResponse.accessToken);
    console.log("logueando4");
    // get return url from query parameters or default to home page
    const returnUrl = router.history.current.query['returnUrl'] || '/';
    console.log("logueando555"+returnUrl);
    router.push(returnUrl).catch((err) => {
        console.log("ddfsdfdfdf");
        console.log(err);
    });
    console.log("logueando6");
}

async function apiAuthenticate(nombreGym, accessToken) {
    // authenticate with the api using a facebook access token,
    // on success the api returns an account object with a JWT auth token
    console.log("holaaaaaaaaa1111111111111");
    console.log(accessToken);
    const response = await axios.post(`${baseUrlLogin}/facebook`, {"nombreGimnasio":nombreGym, "accessToken": accessToken },{withCredentials: true});
    console.log("holaaaaaaaaa22222222222222");
    const authentication = response.data;
    console.log("holaaaaaaaaa333");
    console.log(response);
    console.log("headers");
    console.log(response.headers);
    console.log(response.headers['set-cookie']);
    authenticationSubject.next(authentication);
    console.log("holaaaaaaaaa4");
    //startAuthenticateTimer();
    console.log("holaaaaaaaaa5");
    return authentication;
}

function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
    stopAuthenticateTimer();
    authenticationSubject.next(null);
    router.push('/login');
}

function getAll() {
    return axios.get(baseUrlLogin)
        .then(response => response.data);
}

function getById(id) {
    return axios.get(`${baseUrlLogin}/${id}`)
        .then(response => response.data);
}

async function update(id, params) {
    const response = await axios.put(`${baseUrlLogin}/${id}`, params);
    let authentication = response.data;
    // update the current account if it was updated
    if (authentication.id === authenticationSubject.value?.id) {
        // publish updated account to subscribers
        authentication = { ...authenticationSubject.value, ...authentication };
        authenticationSubject.next(authentication);
    }
    return authentication;
}

async function _delete(id) {
    await axios.delete(`${baseUrlLogin}/${id}`);
    if (id === authenticationSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }
}

// helper methods

let authenticateTimeout;

/*function startAuthenticateTimer() {
    // parse json object from base64 encoded jwt token
    console.log("otra vez por aqui");
    const jwtToken = JSON.parse(atob(authenticationSubject.value.token.split('.')[1]));

    // set a timeout to re-authenticate with the api one minute before the token expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    const { accessToken } = FB.getAuthResponse();
    console.log("otra vez por aqui");
    authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
}
*/
function stopAuthenticateTimer() {
    // cancel timer for re-authenticating with the api
    clearTimeout(authenticateTimeout);
}