<template>
    <div class="">
      <Whats></Whats>
      <!--<Loader></Loader>-->
      <Toolbar></Toolbar>
      <!--<Header></Header>-->
      <!--<Ventajas></Ventajas>-->
      <!--<Caracteristicas></Caracteristicas>-->
      <!--<Video></Video>-->
      <!-- <Team></Team> -->
      <Contacto></Contacto>
      <Footer></Footer>
      <Copyright></Copyright>
    </div>
  </template>
  
  <script>
  var $ = require("jquery");
  import Whats from "../components/Whats";
  /*import Loader from "../components/Loader";*/
  import Toolbar from "../components/Toolbar";
//import Header from "../components/Header";
  //import Ventajas from "../components/Ventajas";
  //import Caracteristicas from "../components/Caracteristicas";
  //import Video from "../components/Video";
  // import Team from "../components/Team";
  import Contacto from "../components/Contacto";
  import Footer from "../components/Footer";
  import Copyright from "../components/Copyright";
  export default {
    name: "Inicio",
    components: {
      Whats,
      //Loader,
      Toolbar,
      //Header,
      //Ventajas,
      //Caracteristicas,
      //Video,
      // Team,
      Contacto,
      Footer,
      Copyright,
    },
  
    methods: {
      scrollactive() {
        $(window).on("scroll load", function () {
          if ($(".navbar").offset().top > 60) {
            $(".fixed-top").addClass("top-nav-collapse");
          } else {
            $(".fixed-top").removeClass("top-nav-collapse");
          }
        });
  
        $("body").prepend(
          '<a href="#header" class="back-to-top page-scroll">Back to Top</a>'
        );
        var amountScrolled = 700;
        $(window).scroll(function () {
          if ($(window).scrollTop() > amountScrolled) {
            $("a.back-to-top").fadeIn("500");
          } else {
            $("a.back-to-top").fadeOut("500");
          }
        });
  
  
   
  
  
      //   $(window).on("load", function () {
      //     var preloaderFadeOutTime = 500;
      //     function hidePreloader() {
      //       var preloader = $(".spinner-wrapper");
      //       setTimeout(function () {
      //         preloader.fadeOut(preloaderFadeOutTime);
      //       }, 500);
      //     }
      //     hidePreloader();
      //   });
      },
    },
  
    created() {
      this.scrollactive();
    },
    mounted() {
      // Obtener el fragmento de la URL
      const fragment = window.location.hash;
  
      // fragment contendrá "#contact" en este ejemplo
      console.log(fragment);
      if('#/contact' === fragment) {
        const videoSection = document.getElementById('contact');
        videoSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  </script>