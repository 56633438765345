<template>
    <div>
     <Modal1></Modal1>
     <Modal2></Modal2>
     <Modal3></Modal3> 
     <Modal4></Modal4>   
        <!-- Details 1 -->
    <div class="basic-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-container">
                        <h2>Gestiona la información de tus socios</h2>
                        <p>UNIFIT ofrece una interfaz amigable e intuitiva, para garantizar una mejor experiencia al usuario.</p>
                        <button class="btn-solid-reg popup-with-move-anim" v-on:click="abrirModal('modal1')">CONOCER MÁS</button>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="image-container">
                        <img class="img-fluid" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/undraw_fitness_stats_sht6.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of details 1 -->

    
    <!-- Details 2 -->
    <div class="basic-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="image-container">
                        <img class="img-fluid" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/undraw_healthy_habit_bh5w.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="text-container">
                        <h2>Toma el control de tus de membresías</h2>

                        <p class="parrafomodal">Prueba la mejor manera de organizar y administrar la venta de tus membresías</p>
                        
                        <button class="btn-solid-reg popup-with-move-anim" v-on:click="abrirModal('modal2')">CONOCER MÁS</button>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-2 -->
    <!-- end of details 2 -->


    <!-- Details 3 -->
    <div class="basic-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-container">
                        <h2>Controla y vende productos</h2>
                        <p class="parrafomodal">Sabemos que todo centro deportivo tiene a la venta insumos, UNIFIT te ayuda a tomar el control de tus ventas de una manera fácil.</p>
                        <button class="btn-solid-reg popup-with-move-anim" v-on:click="abrirModal('modal3')">CONOCER MÁS</button>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="image-container">
                        <img class="img-fluid" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/undraw_empty_cart_co35.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of details 3 -->
    
    <!-- Details 4 -->
    <div class="basic-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="image-container">
                        <img class="img-fluid" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/undraw_Quitting_time_re_1whp.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="text-container">
                        <h2>Control de tus empleados</h2>
                        <p class="parrafomodal">La comunicación con tus empleados es uno de los principales factores que influye en el exito de un buen control de tu centro deportivo.</p>
                        <button class="btn-solid-reg popup-with-move-anim" v-on:click="abrirModal('modal4')">CONOCER MÁS</button>
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-2 -->
    <!-- end of details 4 -->
    </div>

</template>
<script>
import Modal1 from '@/components/Modal1';
import Modal2 from '@/components/Modal2';
import Modal3 from '@/components/Modal3';
import Modal4 from '@/components/Modal4';

var $=require('jquery');

export default {
    name:'Caracteristicas',
    components:{Modal1, Modal2, Modal3, Modal4},
    methods:{
        abrirModal(modal){
            switch (modal) {
                case "modal1":
                    $('.modal1').modal('show');
                    console.log("Llego a modal 1");
                    break;
                case "modal2":
                    $('.modal2').modal('show');
                    console.log("Llego a modal 2");
                    break;
                case "modal3":
                    $('.modal3').modal('show');
                    console.log("Llego a modal 3");
                    break;
                case "modal4":
                    $('.modal4').modal('show');
                    console.log("Llego a modal 4");
                    break;
            
                default:
                    break;
            }
        }
    }
}
</script>
<style lang="">
    
</style>