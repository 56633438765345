<template>

<div class="">

<div class="modal fade modalVideo" id="modalVideo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body">
        <!-- <div id="details-lightbox-1" class=""> -->
        <div class="container seccionprincipalmodal">
            <div class="tvModal">
               
                
            <iframe width="100%" height="515" src="https://www.youtube.com/embed/k54rUUYXkeA?si=lA4d7wXk10c-XHkG" title="UNIFIT" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <button type="button" v-on:click="pararycerrar()" id="btnCerrarVideo" class="btn-outline-reg mfp-close as-button">CERRAR</button>                
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    <!-- </div> end of lightbox-basic -->
      </div>
      
    </div>
  </div>
</div>



</div>
        
</template>
<script>
var $=require('jquery');
export default {
    name:'ModalVideo',
    methods: {
        pararycerrar(){

            
            
            $('.modalVideo').modal('hide');
        }
    },
    
}
</script>
<style lang="">
    
</style>