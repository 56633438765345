import Vue from 'vue'
import Router from 'vue-router'
import Inicio from '@/components/Inicio'
import Register from '@/components/Register'
import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/', 
      name: 'Home',
      component: Inicio
    },
    {
      path: '/register', 
      name: 'Register',
      component: Register
    }
  ]
})
