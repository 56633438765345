<template>
    <div>
         <!-- Header -->
    <header id="header" class="header">
        <div class="header-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="text-container">
                            <h1><span class="fit-title">UNIFIT</span> plataforma de gestión para tu centro deportivo</h1>
                            <p class="p-large">Lleva el control de tu gimnasio o centro deportivo a donde quiera que vayas</p>
                            <a class="btn-solid-lg page-scroll" href="#services">COMENZAR</a>
                        </div> <!-- end of text-container -->
                    </div> <!-- end of col -->
                    <div class="col-lg-6">
                        <div class="image-container">
                            <img class="img-fluid" src="../assets/images/unifit.png" alt="alternative">/
                        </div> <!-- end of image-container -->
                    </div> <!-- end of col -->
                </div> <!-- end of row -->
            </div> <!-- end of container -->
        </div> <!-- end of header-content -->
    </header> <!-- end of header -->
    <!-- end of header -->
    
    </div>
</template>
<script>
export default {
    name:'Header'
    
}
</script>
<style>
    
</style>