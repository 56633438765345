<template>
    <div>
        <!-- Services -->
    <div id="services" class="cards-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Que puede hacer UNIFIT por tí</h2>
                    <p class="p-heading p-large">UNIFIT <strong>no es solo una plataforma,</strong> es una herramienta que te devolverá el tiempo que necesitas para cosas más importantes.</p>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="col-lg-12">

                    <!-- Card -->
                    <div class="card">
                        <img class="card-image" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/services-icon-1.svg" alt="alternative">
                        <div class="card-body">
                            <h4 class="card-title">Reduce tiempos</h4>
                            <p>Todas las funcionalidades de la plataforma están diseñadas para realizar las tareas con pocos clics</p>
                        </div>
                    </div>
                    <!-- end of card -->

                    <!-- Card -->
                    <div class="card">
                        <img class="card-image" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/services-icon-2.svg" alt="alternative">
                        <div class="card-body">
                            <h4 class="card-title">Información y estadistica a la mano</h4>
                            <p>Obtenen los datos de tus socios, membresías, ventas, etc, de forma rapida y en tiempo real</p>
                        </div>
                    </div>
                    <!-- end of card -->

                    <!-- Card -->
                    <div class="card">
                        <img class="card-image" src="https://assetswebunifit.s3.amazonaws.com/E489FWJM9057TYW45THJW4JMT9W45U/services-icon-3.svg" alt="alternative">
                        <div class="card-body">
                            <h4 class="card-title">Siéntete seguro</h4>
                            <p>UNIFIT esta desarrollado con los más altos estándares en seguridad y protección de datos.</p>
                        </div>
                    </div>
                    <!-- end of card -->
                    
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of cards-1 -->
    <!-- end of services -->

    </div>
</template>
<script>
export default {
    name:'Ventajas'
}
</script>
<style lang="">
    
</style>