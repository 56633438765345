<template>
        <!-- Footer -->
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="footer-col">
                        <h4>Más información sobre UNIFIT</h4>
                        <p>UNIFIT es un producto de <strong> Lambdax Technologies</strong>, empresa lider en desarrollo de software empresarial</p>
                    </div>
                </div> <!-- end of col -->
                <div class="col-md-4">
                    <div class="footer-col middle">
                        <h4>Visita</h4>
                        <ul class="list-unstyled li-space-lg">
                            <li class="media">
                              
                                <div class="media-body"> <a class="turquoise" href="https://www.unifit.mx">https://www.unifit.mx</a></div>
                            </li>
                            <!-- <li class="media">
                                <i class="fas fa-square"></i>
                                <div class="media-body">Read our <a class="turquoise" href="terms-conditions.html">Terms & Conditions</a>, <a class="turquoise" href="privacy-policy.html">Privacy Policy</a></div>
                            </li> -->
                        </ul>
                    </div>
                </div> <!-- end of col -->
                <div class="col-md-4">
                    <div class="footer-col last">
                        <h4>Redes sociales</h4>
                        <!-- <span class="fa-stack"> -->
                            <a target="_blank" class="media-body" href="https://www.facebook.com/unifitplatform">
                               <strong>facebook:</strong> /unifitplatform
                            </a>
                            <br>
                            <a target="_blank" class="media-body" href="https://www.tiktok.com/@unifit_mx">
                                <strong>Tiktok:</strong> /@unifit_mx
                            </a>
                            <br>
                            <a target="_blank" class="media-body" href="https://www.youtube.com/@UNIFIT-MX">
                                <strong>youtube:</strong> /@UNIFIT-MX
                            </a>
                        <!-- </span> -->
                     <!--    <span class="fa-stack">
                            <a  href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span> -->
                        <!-- <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-google-plus-g fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-linkedin-in fa-stack-1x"></i>
                            </a>
                        </span> -->
                    </div> 
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of footer -->  
    <!-- end of footer -->
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>